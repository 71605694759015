input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

html {
  body {
    overflow-x: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  .app-wrapper {
    height: 100%;

    .main-wrapper {
      overflow-y: auto;
    }
  }
}

.reset {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #438fff;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}

.react-tabs__tab-list {
  border-bottom: 1px solid #707070 !important;
  display: flex;
}

.react-tabs__tab {
  background: #eee;
  border: 1px solid #707070 !important;
  padding: 6px 40px !important;
  opacity: 0.46;
  border-radius: 0px !important;

  &::after {
    content: '';
    width: 0;
    height: 0;
  }

  &:first-child {
    margin-left: 20px;
  }

  &:nth-child(2) {
    margin-left: 4px;
  }

  &--disabled {
    border: 1px solid #707070 !important;
  }

  &--selected {
    background: #fff;
    color: #000;
    opacity: 1;
    border-radius: 0px !important;
    border: 1px solid #707070 !important;
    font-weight: bold;
  }
}

th.ant-table-cell::before {
  content: none !important;
}

thead.ant-table-thead,
thead.ant-table-thead > tr {
  border-top: 4px solid black !important;
  border-bottom: 2px solid #707070 !important;
}

.ant-table-wrapper table {
  border-collapse: collapse !important;
}

tfoot.ant-table-summary,
tfoot.ant-table-summary > tr {
  border-top: 2px solid #707070 !important;
  td {
    font-weight: 600;
  }
}

// .ant-table-header {
//   overflow: auto !important;
// }
